@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-Black.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-BlackIt.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-Bold.otf) format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-BoldIt.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-ExtraLightIt.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-ExtraLight.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-It.otf) format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-LightIt.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-Light.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-Regular.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-Semibold.otf)
      format("otf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: local("Source Sans Pro"),
    url(./assets/images/fonts/SourceSanPro/SourceSansPro-SemiboldIt.otf)
      format("otf");
}

div,
strong,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Source Sans Pro", sans-serif;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  padding: 12px;
  background-color: white;
}

.ant-layout-sider-children,
.ant-menu {
  background-color: #f5f5f5;
  border: none !important;
}

.ant-menu {
  margin-top: 1.5em;
  margin-left: 16px;
  border-radius: 24px 0 24px 0 !important;
  background-color: #ffffff;
  padding: 12px;
}

.ant-menu-item-selected {
  background-color: rgba(132, 204, 50, 0.66) !important;
  color: #555555 !important;
  border-radius: 16px 0 16px 0 !important;
}

.ant-pagination-next {
  border: 1px solid #84cc32 !important;
}
.ant-pagination-next button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.ant-pagination-prev {
  border: 1px solid #84cc32 !important;
}
.ant-pagination-prev button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-pagination-prev svg {
  color: #84cc32;
}

.ant-pagination-next svg {
  color: #84cc32;
}

.ant-table-cell {
  color: #254600 !important;
}

.ant-table-cell div {
  color: #254600 !important;
}

.ant-pagination-item a {
  color: #555555 !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-modal-content {
  padding: 0 !important;
  border-radius: 40px !important;
}

.ant-modal-header {
  background-color: #84cc32 !important;
  padding: 18px;
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
}

.ant-modal-footer {
  padding: 24px;
}

.ant-modal-body {
  padding: 24px;
}

.ant-modal-close-x span svg {
  color: white;
}
.ant-modal-close {
  right: 25px !important;
  top: 20px !important;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item-ellipsis,
.ant-pagination-item-link {
  border-top-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.ant-pagination-item {
  border: 1px solid #555555 !important;
}

.ant-pagination-item-active {
  background-color: #84cc32 !important;
  border-color: #84cc32 !important;
}

.ant-pagination-item-ellipsis {
  border: 1px solid #555555;
}

.ant-form-item-required {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #555555 !important;
}

.ant-input-large {
  height: 46px;
  font-size: 16px !important;
  text-align: left !important;
  color: #555555 !important;
  border-color: #555555 !important;
  outline: none !important;
}

.ant-input-full {
  height: 100% !important;
}

.ant-message-success {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #84cc32;
}

.ant-message-error {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #b3261e;
}

.ant-message-notice-content:has(.ant-message-success) {
  border-radius: 8px;
  border: 1px solid #84cc32;
}
.ant-message-notice-content:has(.ant-message-error) {
  border-radius: 8px;
  border: 1px solid #b3261e;
}

.scroller::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroller::-webkit-scrollbar-track-piece,
.ant-table-body::-webkit-scrollbar-track-piece {
  background-color: white;
}

.scroller::-webkit-scrollbar-thumb:vertical,
.ant-table-body::-webkit-scrollbar-thumb:vertical {
  height: 8px;
  background-color: #84cc32;
}

.scroller,
.ant-table-body {
  scrollbar-color: #84cc32 white;
  scrollbar-width: thin;
}

.ant-custom-switch {
  background-color: #84cc32 !important;
}

@media screen and (max-width: 992px) {
  .ant-layout-sider {
    position: fixed !important;
    left: 0 !important;
    z-index: 99999999 !important;
  }
}

.ant-menu-title-content , .ant-menu-item-icon{
  color: rgb(85, 85, 85) !important;
  font-size: 16px;
}

.ant-menu-submenu-arrow::after{
  background-color: rgba(132, 204, 50, 1) !important;
}

.ant-menu-submenu-arrow::before{
  background-color: rgba(132, 204, 50, 1) !important;
}

.ant-menu-sub {
  background-color: unset !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover{
  color: rgba(132, 204, 50, 1) !important;
 
}

.ant-tabs-tab-btn{
  font-size: 18px !important;
}

.ant-tabs-ink-bar-animated{
  background-color: rgba(132, 204, 50, 1) !important ;
}

.ant-switch-checked{
  background-color: rgba(132, 204, 50, 1) !important ;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color: rgba(132, 204, 50, 1) !important ;
}

.ant-checkbox-checked::after{
  border: 2px solid rgba(132, 204, 50, 1) !important ;
}

.ant-checkbox-checked .ant-checkbox-inner{
  border-color: rgba(132, 204, 50, 1) !important ;
}
